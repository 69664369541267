<template>
  <j-abm
    name="store"
    avatar="shop"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: false,
    }"
    @create="onCreate"
    @update="onUpdate">
    <template #form="{item}">
      <div class="col-xl-5 col-lg-12">
        <h5>{{ $t('template') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
          ref="title"
          :model="item"
          :rules="rules">
          <j-input
            prop="name"
            v-model="item.name"
            icon="shop"
            :format="f.title" />
        </a-form-model>
        <a-form-model
          ref="address"
          :model="item.address"
          :rules="rules">
          <j-input
            prop="street"
            v-model="item.address.street"
            icon="environment" />
          <j-input
            prop="apartment"
            v-model="item.address.apartment"
            icon="bell" />
          <j-zip
            prop="zip"
            v-model="item.address.zip"
            icon="global"
            @change="zone => onZoneChange(item, zone)"/>
          <j-input
            prop="city"
            v-model="item.address.city"
            icon="global" />
          <j-input
            prop="state"
            v-model="item.address.state"
            icon="global" />
        </a-form-model>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import v from '@/utils/validators'
import f from '@/utils/formatters'
import JAbm from '@/views/shared/abm/update'
import JInput from '@/views/shared/forms/input'
import JZip from '@/views/shared/forms/zip'
export default {
  components: {
    JAbm,
    JInput,
    JZip,
  },
  data: function () {
    return {
      f,
      rules: {
        name: [v.required],
        street: [v.required],
        city: [v.required],
        state: [v.required],
        zip: [v.required],
      },
    }
  },
  computed: {
    account() {
      return this.$route.params.account
    },
    suppliers() {
      return {
        fetchOne: id => lms.account.fetchStore(this.account, id),
        payload: () => {
          return {
            address: {},
          }
        },
      }
    },
  },
  methods: {
    onZoneChange(item, zone) {
      item.address.city = zone.meta.city
      item.address.state = zone.meta.state
      item.address.country = zone.meta.country
    },
    onCreate(store) {
      this.onSubmit(store, store => lms.account.createStore(this.account, store))
    },
    onUpdate(store) {
      this.onSubmit(store, store => lms.account.updateStore(this.account, store))
    },
    onSubmit(store, callback) {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.title.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.address.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          callback(store).then(_ => this.$router.push(`/accounts/${this.account}/stores`))
        }
      })
    },
  },
}
</script>
