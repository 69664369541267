var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"store","avatar":"shop","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: false,
  }},on:{"create":_vm.onCreate,"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"form",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"col-xl-5 col-lg-12"},[_c('h5',[_vm._v(_vm._s(_vm.$t('template')))])]),_c('div',{staticClass:"col-xl-7 col-lg-12"},[_c('a-form-model',{ref:"title",attrs:{"model":item,"rules":_vm.rules}},[_c('j-input',{attrs:{"prop":"name","icon":"shop","format":_vm.f.title},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('a-form-model',{ref:"address",attrs:{"model":item.address,"rules":_vm.rules}},[_c('j-input',{attrs:{"prop":"street","icon":"environment"},model:{value:(item.address.street),callback:function ($$v) {_vm.$set(item.address, "street", $$v)},expression:"item.address.street"}}),_c('j-input',{attrs:{"prop":"apartment","icon":"bell"},model:{value:(item.address.apartment),callback:function ($$v) {_vm.$set(item.address, "apartment", $$v)},expression:"item.address.apartment"}}),_c('j-zip',{attrs:{"prop":"zip","icon":"global"},on:{"change":function (zone) { return _vm.onZoneChange(item, zone); }},model:{value:(item.address.zip),callback:function ($$v) {_vm.$set(item.address, "zip", $$v)},expression:"item.address.zip"}}),_c('j-input',{attrs:{"prop":"city","icon":"global"},model:{value:(item.address.city),callback:function ($$v) {_vm.$set(item.address, "city", $$v)},expression:"item.address.city"}}),_c('j-input',{attrs:{"prop":"state","icon":"global"},model:{value:(item.address.state),callback:function ($$v) {_vm.$set(item.address, "state", $$v)},expression:"item.address.state"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }