class Scheduler {
  constructor(supplier, delay) {
    this.timeout = undefined
    this.current = undefined
    this.supplier = supplier
    this.delay = delay
  }

  schedule(value) {
    return new Promise((resolve, reject) => this.doSchedule(value, resolve, reject))
  }

  doSchedule(value, resolve, reject) {
    const self = this
    if (self.timeout) {
      clearTimeout(self.timeout)
      self.timeout = null
    }
    self.current = value
    function action() {
      self.supplier(value)
        .then(r => {
          if (self.current === value) {
            resolve(r)
          }
        })
        .catch(reject)
    }
    if (value) {
      self.timeout = setTimeout(action, self.delay)
    }
  }
}

export default (supplier, delay = 200) => new Scheduler(supplier, delay)
