<template>
  <a-form-model-item prop="zip" :colon="false" :rules="rules" has-feedback>
    <span slot="help" class="text-secondary">{{ help }}</span>
    <span slot="label" class="text-dark">{{$t('zip')}}</span>
    <a-auto-complete
      :default-value="value"
      :placeholder="value"
      option-label-prop="title"
      size="large"
      @select="onSelect"
      @search="onSearch">
      <template slot="dataSource">
        <a-select-option
          v-for="zone in zones"
          :key="String(zone.id)"
          :title="value">
          <strong>{{ zone.meta.city }}</strong>, {{ zone.meta.state }}
        </a-select-option>
      </template>
    </a-auto-complete>
  </a-form-model-item>
</template>

<script>
import lms from '@/api/lms'
import scheduler from '@/utils/scheduler'

const scheduller1 = scheduler(query => lms.zone.searchByZip(query))

export default {
  props: [
    'value',
    'help',
    'rules',
  ],
  data() {
    return {
      zones: [],
    }
  },
  methods: {
    onSearch(value) {
      this.$emit('input', value)
      this.zones = []
      if (value && value.length >= 4) {
        scheduller1.schedule(value)
          .then(r => {
            this.zones = r.data
          })
      }
    },
    onSelect(value) {
      const zone = this.zones.find(o => String(o.id) === value)
      this.$emit('change', zone)
    },
  },
}
</script>
